/* Header-specific styles */
.language-selector-header-wrapper {
  display: flex; /* Flex display for alignment */
  align-items: center; /* Center alignment */
  cursor: pointer; /* Pointer cursor for dropdown icon */
  padding-right: 20px; /* Add 8px padding around the entire component */
}

.language-selector-header .MuiSelect-select {
  display: flex; /* Flex to align icon */
  align-items: center; /* Center the icon */
  width: auto; /* Set width to auto to fit the content */
}

.language-selector-header .MuiSelect-icon {
  display: none; /* Hide the default dropdown arrow */
}

.language-selector-header .MuiOutlinedInput-root {
  width: auto; /* Set width to auto to fit the icon */
}

.language-selector-header .MuiOutlinedInput-notchedOutline {
  border: none !important; /* Remove outline border */
}

.language-selector-header .MuiOutlinedInput-input {
  padding: 0 !important; /* Remove padding inside the input */
  margin: 0 !important; /* Remove margin inside the input */
  border: none !important; /* Remove border */
}
/* Dropdown List Item Styles (Common) */
.MuiList-root .MuiMenuItem-root {
  padding: 20px 16px; /* Comfortable padding */
  font-size: 15px; /* Consistent font size */
  cursor: pointer; /* Pointer cursor for better UX */
  transition: background-color 0.2s ease; /* Smooth transition for hover effects */
  border: none !important; /* Remove any border */
}

.MuiList-root .MuiMenuItem-root.Mui-selected {
  color: #0073e6; /* Selected item text color */
  border: none !important; /* Remove any border */
}
