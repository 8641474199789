* {
  font-family: roboto, sans-serif;
}

.cls-1 {
  stroke-miterlimit: 10;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4,
._05_Pt_Stroke {
  stroke-width: 0.5px;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4,
._05_Pt_Stroke,
.cls-5,
.cls-20,
.cls-6,
.cls-7,
.cls-8 {
  stroke-linecap: round;
}

.cls-1,
.cls-9,
.cls-3,
.cls-10,
.cls-4,
._05_Pt_Stroke,
.cls-11,
.cls-5,
.cls-6,
.cls-7,
.cls-20,
.cls-8 {
  stroke: #010101;
}

.cls-1,
.cls-3,
.cls-20,
.cls-12 {
  fill: #fff;
}

.cls-2 {
  stroke: #231f20;
}

.cls-2,
.cls-9,
.cls-3,
.cls-10,
.cls-4,
._05_Pt_Stroke,
.cls-11,
.cls-5,
.cls-20,
.cls-6,
.cls-7,
.cls-8 {
  stroke-linejoin: round;
}

.cls-2,
.cls-9,
.cls-10,
.cls-4,
._05_Pt_Stroke,
.cls-11,
.cls-5,
.cls-6,
.cls-7,
.cls-8 {
  fill: none;
}

.cls-9,
.cls-7 {
  stroke-width: 0.4px;
}

.cls-10 {
  stroke-width: 0.4px;
}

.cls-4,
.cls-13,
.cls-14 {
  display: none;
}

.cls-13,
.cls-15 {
  fill: #010101;
}

.cls-13,
.cls-15,
.cls-12 {
  stroke-width: 0px;
}

.cls-11 {
  stroke-width: 0.4px;
}

.cls-5,
.cls-20 {
  stroke-width: 0.8px;
}

.cls-8 {
  stroke-width: 0.8px;
}

/**
 ** Tailwind-like styles
 ** These have been moved to src/styles/frontshine.css
 **/
