/**
 ** #######                             #####
 ** #       #####   ####  #    # ##### #     # #    # # #    # ######
 ** #       #    # #    # ##   #   #   #       #    # # ##   # #
 ** #####   #    # #    # # #  #   #    #####  ###### # # #  # #####
 ** #       #####  #    # #  # #   #         # #    # # #  # # #
 ** #       #   #  #    # #   ##   #   #     # #    # # #   ## #
 ** #       #    #  ####  #    #   #    #####  #    # # #    # ######
 **
 ** FrontShine CSS
 **
 ** An ode to TailwindCSS, these are utility-first CSS classes that
 ** aim to speed up development time in a similar fashion to TailwindCSS.
 **
 ** Notes:
 ** - An underscore indicates an absolute value. EX:`text-size_40px` will set the font-size to 40px.
 ** - A backslash indicates a fraction. EX: `w-1\2` will set the width to 50%.
 **
 ** Usage:
 ** import 'styles/frontshine.css'; // Add to any component that needs these classes
 **
 ** TODO:
 ** - Add !important to all classes
 ** - Fill out arbitrary values
 */

:root {
  --primary-blue: #003366;
}

/** Position **/
.relative {
  position: relative !important;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

/** Display **/
.block {
  display: block;
}

/** Flexbox classes **/
.flex {
  display: flex !important;
  flex-direction: column !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-col-reverse {
  flex-direction: column-reverse !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-1 {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}

/** Justify Content **/
.justify-start {
  justify-content: flex-start !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between !important;
}

/** Align Items **/
.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

/** Align Self **/
.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

/** Width **/
.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw;
}

.w-1\5 {
  width: 20%;
}

.w-1\4 {
  width: 25%;
}

.w-1\3 {
  width: 33.333333%;
}

.w-1\/2 {
  width: 50%;
}

.w-2\3 {
  width: 66.666667%;
}

.w-3\4 {
  width: 75%;
}

/* Height */
.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-1\5 {
  height: 20%;
}

.h-1\4 {
  height: 25%;
}

.h-1\3 {
  height: 33.333333%;
}

.h-1\/2 {
  height: 50%;
}

.h-2\3 {
  height: 66.666667%;
}

.h-3\4 {
  height: 75%;
}

.max-h-70-percent {
  max-height: 70% !important;
}

/** Margin **/

.m-0 {
  margin: 0 !important;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my_20px {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.no-vertical-margin {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt_20px {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb_20px {
    margin-bottom: 20px !important;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}


.mx_24px {
  margin-left: 24px;
  margin-right: 24px;
}


/* Padding */
.p-0 {
  padding: 0 !important;
}

.px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.px_16px {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.pl_16px {
  padding-left: 16px !important;
}

/** Text Decoration **/
.text-decoration-none {
  text-decoration: none;
}

.text-red {
  color: red !important;
}

.text-orange {
  color: orange !important;
}

.text-gray {
    color: #808080 !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-xxs {
  font-size: 0.625rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-size_12px {
  font-size: 12px !important;
}

.text-size_14px {
  font-size: 14px !important;
}

.text-size_16px {
  font-size: 16px !important;
}

.text-size_20px {
  font-size: 20px !important;
}

.text-size_24px {
  font-size: 24px !important;
}

.text-size_28px {
  font-size: 28px !important;
}

.text-size_32px {
  font-size: 32px !important;
}

.text-size_36px {
  font-size: 36px !important;
}

.text-size_40px {
  font-size: 40px !important;
}

.bold-text {
  font-weight: bold !important;
}

.underline-text {
  text-decoration: underline !important;
}

/** Font **/
.font-semibold {
  font-weight: 600 !important;
}

.font-bolder {
  font-weight: bolder !important;
}

/** Background Color **/
.bg-grey {
  background-color: #eeeeee !important;
}

.bg-green {
  background-color: green !important;
}

.bg-235 {
  background-color: rgba(235, 235, 235) !important;
}

.bg-primary-blue {
  background-color: var(--primary-blue) !important;
}


/** Radius **/
.radius_10px {
  border-radius: 10px !important;
}

.radius_20px {
  border-radius: 20px !important;
}

.max-w_320px {
  max-width: 320px !important;
}

/** Colors **/
.james-bond-blue {
  color: #007bff;
}

.text-black {
  color: #000000 !important;
}

.text-primary-blue {
  color: var(--primary-blue) !important;
}

.text-white {
  color: #ffffff !important;
}

/* Spacing — Area between elements in the same direction */
.space-y-0 {
  margin-top: 0;
}

.space-y-0\.5 {
  margin-top: 0.125rem;
}

.space-y-1 {
  margin-top: 0.25rem !important;
}

.space-y-1\.5 {
  margin-top: 0.375rem;
}

.space-y-2 {
  margin-top: 0.5rem;
}

.space-y-2\.5 {
  margin-top: 0.625rem;
}

.space-y-3 {
  margin-top: 0.75rem;
}

.space-y-3\.5 {
  margin-top: 0.875rem;
}

.space-y-4 {
  margin-top: 1rem;
}

.space-y-5 {
  margin-top: 1.25rem;
}

.space-y-6 {
  margin-top: 1.5rem;
}

.space-y-7 {
  margin-top: 1.75rem;
}

.space-y-8 {
  margin-top: 2rem;
}

.space-y-9 {
  margin-top: 2.25rem;
}

.space-y-10 {
  margin-top: 2.5rem;
}

.space-y-11 {
  margin-top: 2.75rem;
}

.space-y-12 {
  margin-top: 3rem;
}

.space-x-0 {
  margin-left: 0;
}

.space-x-0\.5 {
  margin-left: -0.125rem;
}

.space-x-1 {
  margin-left: -0.25rem;
}

.space-x-1\.5 {
  margin-left: -0.375rem;
}

.space-x-2 {
  margin-left: -0.5rem;
}

.space-x-2\.5 {
  margin-left: -0.625rem;
}

.space-x-3 {
  margin-left: -0.75rem;
}

.space-x-3\.5 {
  margin-left: -0.875rem;
}

.space-x-4 {
  margin-left: -1rem;
}

.space-x-5 {
  margin-left: -1.25rem;
}

.space-x-6 {
  margin-left: -1.5rem;
}

.space-x-7 {
  margin-left: -1.75rem;
}

.space-x-8 {
  margin-left: -2rem;
}

.space-x-9 {
  margin-left: -2.25rem;
}

.space-x-10 {
  margin-left: -2.5rem;
}

.space-x-11 {
  margin-left: -2.75rem;
}

.space-x-12 {
  margin-left: -3rem;
}

/* Aspect Ratio */
.aspect-auto {
  aspect-ratio: auto !important;
}

.aspect-square {
  aspect-ratio: 1 / 1 !important;
}

.aspect-16\/9 {
  aspect-ratio: 16 / 9 !important;
}

/* Make a CSS class for a Material UI DataGrid cell that makes a bottom solid border of black */
.border-bottom-black {
  border-bottom: 1px solid black;
}



/* Z-Index */
.z-10 {
  z-index: 10 !important;
}

.z-100 {
  z-index: 100 !important;
}



/* Position */
.top-0 {
  top: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.right-0 {
  right: 0 !important;
}

/* Rows */
.row-success {
  background-color: #c8eece !important;
  border: 2px solid green !important;
}

.row-needs-editing {
  background-color: #eeeeee !important;
  border: 2px solid red !important;
}

.row-disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* Cursor */
.cursor-pointer {
  cursor: pointer !important;
}

/* Force all <input /> to text-align center */
.center-text-inputs input {
  text-align: center !important;
}

.left-text-inputs input {
  text-align: left !important;
}

.left-text-inputs-forced input {
  text-align: left !important;
  margin-right: auto !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.needs-selection {
  background-color: #f8d7da !important;
}

.warning-bg {
    background-color: #ece5c1 !important;
}

.unselectable {
    user-select: none !important;
/*  Completely disable */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: not-allowed !important;
}

.pinned-left-column {
  position: sticky !important;
  left: 0 !important;
  z-index: 10 !important;
  background-color: pink !important; /* Adjust based on your theme */
  box-shadow: 1px 0 2px 0 rgba(0,0,0,0.12) !important; /* Optional shadow for better visual separation */
}
